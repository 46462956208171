import BiovationsDomestic from '../../pages/laneview/forms/biovationsdomestic/BiovationsDomestic';
import GtmsInbound from '../../pages/laneview/forms/gtmsInbound/GtmsInbound';
import { useParams } from 'react-router-dom';
import React from 'react';

const FormRenderer = () => {
    const { id } = useParams();

    let formComponent;
    switch (id) {
      case '1':
        formComponent = <BiovationsDomestic />;
        break;
      case '3':
        formComponent = <GtmsInbound />;
        break;
      case '5':
        formComponent = <GtmsInbound />;
        break;
      default:
        formComponent = <div>Form not found</div>;
    }

  return (
    <div>
      {formComponent}
    </div>
  );

}

export default FormRenderer