import { storeSidebarData } from "../../redux/slices/sidebarSlice";
import TextsmsIcon from '@mui/icons-material/Textsms';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { useDispatch } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Cookies from 'js-cookie';
import * as React from 'react';

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const logout = async() => {
    const cookieNames = ['gtms-cp-auth', 'cp-a', 'cp-csn', 'cp-cn', 'cp-admin', 'cp-ci', 'cp-un'];
    cookieNames.forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    dispatch(storeSidebarData([]));
    navigate('/');
  }
 
  const navigateEnhancement = async() => {
    navigate('/Enhancement');
  }

  return (
  <div className='navbar'>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Customer Portal
          </Typography>
          <Button id='enhancementPageNavBtn' color="inherit" style={{ fontSize: '2em' }} onClick={navigateEnhancement}><TextsmsIcon /></Button>
          <Button color="inherit" onClick={logout}>LOG OUT</Button>
        </Toolbar>
      </AppBar>
    </Box>
  </div>
  )
}
export default NavBar