import Sidebar from '../../components/sidebar/Sidebar';
import NavBar from '../../components/navbar/NavBar';
import { config } from '../../config/Constants';
import { Button } from '@mui/material';
import React, {useState} from 'react';
import Cookies from 'js-cookie';
import './enhancement.css';
import Axios from 'axios';

const Enhancement = () => {
  const [commentType, setCommentType] = useState();
  const [comment, setComment] = useState();
  const textBoxMax = 1000;

  const textBoxCharCount = () => {
    let count = textBoxMax;
    if (comment != undefined){
      count = textBoxMax - comment.length
    } 
    return count
  };

  const validateSubmit = (commentType, comment) => {
    let pass = true;
    let missingList = []
    if (commentType == undefined || commentType == 'NONE'){
      missingList.push('Type');
      pass = false;
    };
    if (comment == undefined || comment.replace(' ', '').length == 0){
      missingList.push('Comment');
      pass = false
    };

    return {
      status: pass,
      missing: missingList
    }
  };

  const handleSubmit = async() => {
    let validationPass = false;
    let validationResult;
    let missingList;

    try {
      
      validationResult = validateSubmit(commentType,comment);
      validationPass = validationResult.status;
      missingList = validationResult.missing;
    }
    catch (err) {
      alert("Ops! An error occurred during submission. Please try again!\nIf problem persists, please try again later!");
    }

    if (validationPass == true) {
      const response = await Axios.post(config.url.SUBMIT_ENHANCEMENT, {
        commentType: commentType,
        comment: comment,
        userName: Cookies.get('cp-un') 
      });

      if (response.data.isSuccessful == true) {
        alert("Submission Success! Thank you for your comment!");
        window.history.back();
      }
      else {
        alert("Ops! An error occurred during submission. Please try again!\nIf problem persists, please try again later!");
      }
    }
    else {
      let default_msg = "Sorry! Something's Wrong!";
      if (missingList.length > 0){
        let missing_msg = "\nMissing Input from the Following Field/s:"
        for (let i = 0; i < missingList.length; i++){
          missing_msg += "\n - "+missingList[i]
        }
        default_msg += missing_msg;
      }
      alert(default_msg);
    }
  };

  const handleCancel = () => {
    const userConfirmed = window.confirm("Are you sure?\nYour comment is not yet submitted.\nCurrent progess will be lost.\nHit 'Cancel' to stay in page.");
    if (userConfirmed) {
      window.history.back();
    }
  }

  return (
    <div className='enahncementPage'> 
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="enhancementPageContainer">
          <div className="enhancementPageLeft">
            <div className="enhancementPageHeader">
              <h1>Comment on Customer Portal?</h1>
            </div>
            <div className="enhancementPageCategory">
              <p>I Would Like to:</p>
              <select id="enhancementPageCategorySelect" 
                onChange={(e) => setCommentType(e.target.value)} 
                required>
                  <option value="NONE" disabled selected>Choose a Type</option>
                  <option value="NEW">Propose a New Feature</option>
                  <option value="BUG">Report a Bug</option>
                  <option value="SUGGESTION">Suggest an Improvment</option>
                  <option value="OTHER">Other</option>
              </select>
            </div>
            <div className="enhancementPageTextLimit">
              <h3>Comment:</h3>
            </div>
            <div className="enhancementPageTextBox">
              <textarea 
                id="enhancementPageComment" 
                onChange={(e) => setComment(e.target.value)} 
                maxLength={textBoxMax} 
                placeholder="Please comment here..." 
                required>
              </textarea>
            </div>
            <div className="enhancementPageTextLimitCurrent">
              <p>Character Remaining: {textBoxCharCount()}</p>
            </div>
            <div className="enhancementPageButtonSection">
              <div className="enhancementPageSubmitButton">
                <Button 
                  variant="contained" 
                  type="submit" 
                  value="Submit"
                  onClick={handleSubmit}>
                    Submit
                </Button>
              </div>
              <div className="enhancementPageCancelButton">
                <Button 
                  variant="contained" 
                  type="cancel" 
                  value="Cancel" 
                  onClick={handleCancel}>
                    Cancel
                </Button>
              </div>
            </div>
          </div>
          <div className="enhancementPageRight">
            <div className="enhancementPageQuickContactHeader">
              <h2>Other Questions:</h2>
            </div>
            <div className="enhancementPageQuickContactBox">
              <h4 className="enhancementPageQuickContactBoxInfo">Account Service:</h4>
              <p className="enhancementPageQuickContactBoxInfo">accountservice@gtms.us</p>
              <br></br>
              <h4 className="enhancementPageQuickContactBoxInfo">Remittance:</h4>
              <p className="enhancementPageQuickContactBoxInfo">remit@gtms.us</p>
              <br></br>
              <h4 className="enhancementPageQuickContactBoxInfo">Laneview:</h4>
              <p className="enhancementPageQuickContactBoxInfo">laneview@gtms.us</p>
              <br></br>
              <h4 className="enhancementPageQuickContactBoxInfo">Webform Support:</h4>
              <p className="enhancementPageQuickContactBoxInfo">webformsupport@gtms.us</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Enhancement;