import RequestingCompany from '../../../pages/laneview/forms/domestic/RequestingCompany';
import OriginInformation from '../../../pages/laneview/forms/domestic/OriginInformation';
import OriginLocation from '../../../pages/laneview/forms/domestic/OriginLocation';
//import Accessorials from '../../../pages/laneview/forms/domestic/Accessorials';
import PoDetails from '../../../pages/laneview/forms/domestic/PoDetails';
import React from 'react';

const useStepRenderer = () => {
    const renderStep = (stepIndex, formData, handleChange, handleChangeDetails, addNewEntry) => {
      switch (stepIndex) {
        case 0:
          return (
            <RequestingCompany 
              formData={formData.requestingCompany} 
              handleChange={handleChange} 
              poList={formData.poList} 
            />
          );
        case 1:
          return (
            <OriginInformation 
              formData={formData.originInformation} 
              handleChange={handleChange} 
            />
          );
        case 2:
          return (
            <OriginLocation 
              formData={formData.originLocation} 
              handleChange={handleChange} 
            />
          );
          /*
        case 3:
          return (
            <Accessorials 
              formData={formData.accessorial} 
              handleChange={handleChange} 
              formType={{type: 'K2J Domestic Form', 'company': 'K2J'}}
            />
          );
          */
        case 3:
          return (
            <PoDetails 
              poDetail={formData.poDetails} 
              handleChange={handleChangeDetails} 
              addNewEntry={addNewEntry}/>
            );
        default:
          return <div>Step {stepIndex + 1}</div>;
      }
    };
      return renderStep;
}

export default useStepRenderer