import { ModeFilter, DateFilter, CarrierFilter, AccountNumberFilter, SelectFilter} from './FilterComponents';
import useFetchAccountNumbers from "../../../../hooks/filter/useFetchAccountNumbers";
import { setChartFilteredData } from "../../../../redux/slices/chartfilterSlice";
import useSelectedOptions from "../../../../hooks/components/useSelectedOptions";
import filterPanelMap from '../../../../utils/filterPanelMap.json';
import { config } from '../../../../config/Constants';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import React  from 'react';
import './filterPanel.css';
import Axios from 'axios';

const FilterPanel = ({chartId, reportId}) => {
  const dispatch = useDispatch();
  const filterOptions = filterPanelMap['chart'][chartId];

  // HOOKS
  const [selectedOptions, handleSelectValue] = useSelectedOptions();
  // TODO: This needs to be cleaned up on not to run every render for every filter
  const accountNumberItems = useFetchAccountNumbers();

  // FILTER COMPONENT REFERENCE
  const filterComponents = {
    'Mode': <ModeFilter handleSelectValue={handleSelectValue} />,
    // No longer used
    'Start-Date': <DateFilter label="Start-Date" handleSelectValue={handleSelectValue} />,
    // No longer used
    'End-Date': <DateFilter label="End-Date" handleSelectValue={handleSelectValue} />,
    'Carrier': <CarrierFilter handleSelectValue={handleSelectValue} />,
    'Account-Number': <AccountNumberFilter handleSelectValue={handleSelectValue} accountNumberItems={accountNumberItems} />,
    // No longer used
    "Select-Filter": filterOptions && filterOptions["menu-items"] ? (
        <SelectFilter handleSelectValue={handleSelectValue} items={filterOptions["menu-items"]}/> 
      ) : null,
    // Origin-State: // Call the originState Filter -- You would need a hook to get the data to pass into the filter
  };

  const getData = async(type) => {
    const response = await Axios.get(config.url.SEARCH_PANEL_FILTER, {
      params: {
        type: type,
        chartId: chartId,
        selectedValues: selectedOptions,
        companyName: Cookies.get('cp-cn') }
    });
    const filterData = {chartId: chartId, data: response.data};
    dispatch(setChartFilteredData(filterData));
  };

  const handleSubmit = () => { getData('chart'); };

  return (
    <div className="filter-panel">
      <div className="filter-content">
        <div className="filter-fields">
          {filterOptions.labels.map((option, index) => (
            <div className='filter-field' key={index}>
              {filterComponents[option] && React.cloneElement(filterComponents[option])}
            </div>
          ))}
        </div>
        <div className="filter-submit">
          <Button variant="outlined" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel