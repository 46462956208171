import { createSlice } from "@reduxjs/toolkit";

const initialState = { reportFilter: [{id: Date.now(), field: '', operator: '', value: ''}], };

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setReportFilter: (state, action) => {
            state.reportFilter = action.payload;
        },
    },
});

export const { setReportFilter } = reportSlice.actions;
export default reportSlice.reducer;