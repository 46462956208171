import ButtonController from '../../../components/containers/buttonController';
import DynamicTable from '../../../components/tables/dynamicTable';
import { getFilteredReport } from '../../../utils/api/reportData';
import useReportData from '../../../hooks/data/userReportData';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import './laneviewTrends.css';

const LaneviewTrends = () => {
  const [reportData, setReportData] = useState([]);

  // Redux state of filtered data
  const reportFilters = useSelector((state) => state.report.reportFilter);

  // Use Report Hook (Pass in Report Name as Param)
  const initialData = useReportData('laneviewtrend');

  // Set initial data inside table
  useEffect(() => {
    if (initialData) {
      setReportData(initialData);
    }
  }, [initialData]);

  // Use Effect if reportFilters have been changed
  useEffect(() => {
    if ( reportFilters[0] && reportFilters[0].field !== '' && reportFilters[0].operator !== '' && reportFilters[0].value !== '') {
      filterReport();
    }
  }, [reportFilters]);

  // UseEffect calls function if filtered data to re-render report
  const filterReport = async() => {
    const filteredData = await getFilteredReport('laneviewtrend', Cookies.get('cp-cn'), reportFilters);
    setReportData({ data: filteredData, size: 'small' });
  }

  return (
    <div className='laneviewTrendsPage'> 
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="laneviewTrendsPageContent">
          <div className="laneviewTrendsTop">
            <div className="laneviewTrendsTitle">
              <h1>Laneview Trends</h1>
            </div>
          </div>
          <div className="laneviewTrendsMid">
            <div className="reportSpaced">
              <div className="report">
                <DynamicTable dataObject={reportData}/>
              </div>
              <div className="reportButtons">
                <ButtonController reportId={8}/>
              </div>
            </div>
          </div>
          <div className="laneviewTrendsBottom"></div>
        </div>
      </div>
    </div>
  )
}

export default LaneviewTrends