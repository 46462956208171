import OceanTrackingMap from '../../pages/tracking/ocean/TrackingMap';
import { useParams } from 'react-router-dom';
import React from 'react';

const TrackingRenderer = () => {
    const { id } = useParams();

    let trackingComponent;
    switch (id) {
      case '1':
        trackingComponent = <OceanTrackingMap />;
        break;
      case '3':
        trackingComponent = <OceanTrackingMap/>;
        break;
      default:
        trackingComponent = <div>Tracking report not found</div>;
    }

  return (
    <div>
      {trackingComponent}
    </div>
  );
}

export default TrackingRenderer