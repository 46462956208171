import Axios from 'axios';
import { config } from '../../config/Constants';

export const getSelectData = async (data) => {
    try {
      const response = await Axios.get(config.url.GET_SELECT_DATA, {
        params: {
          selectValues: data.selectValues,
          tableName: data.tableName,
          whereField: data.whereField,
          whereValue: data.whereValue,
          orderBy: data.orderBy,
          db: data.db
        }
      });
      console.log(data)
      return response.data.dataResults; 
    } catch (error) {
      console.log('Error fetching select fields', error);
      return [];
    }
  }