import { getSelectData } from '../../utils/api/data';
import { useState, useCallback } from 'react';

const useOperatorField = () => {
  const [operatorSelect, setOperatorSelect] = useState({});

  const handleOperatorFieldChange = useCallback(async (rowId, value, fieldNames) => {
    const findId = (field) => fieldNames.find(item => item.FIELD_NAME === field)?.ID;
    const id = findId(value);

    if (id === undefined) {
      return;
    }

    const operatorValues = await getSelectData({
      selectValues: 'OPERATOR_NAME', 
      tableName: 'CustomerPortal.reports.SELECT_FIELD_OPERATORS',
      whereField: 'FIELD_ID', 
      whereValue: id,
    });

    const operators = operatorValues.map(item => item.OPERATOR_NAME);

    setOperatorSelect((prev) => ({
      ...prev, [rowId]: operators
    }));

  }, []);

  return { operatorSelect, handleOperatorFieldChange };
}; 

export default useOperatorField;