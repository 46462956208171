import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { getRowColor, getDefaultRowColor } from './utils/dynamicTableUtils';
import React, { useState, useEffect } from 'react';

const DynamicTable = ({ dataObject }) => {
  const [tableSize, setTableSize] = useState('medium');
  
  useEffect(() => {
    if (dataObject && dataObject.size) {
      setTableSize('small');
    }
  }, [dataObject]);


  if (!dataObject || !dataObject.data || !Array.isArray(dataObject.data) || dataObject.data.length === 0) {
    return <p>Currently, no information is available. <b/> Please choose a different value.</p>
  }

  const fieldNames = dataObject.data.length > 0 ? Object.keys(dataObject.data[0]) : [];

  return (
      <TableContainer component={Paper} sx={{height: '100%'}}>
        <Table sx={{minWidth: 650, backgroundColor: '#F5F5F5' }} size={tableSize} aria-label="simple table">
          <TableHead>
            <TableRow>
                {fieldNames.map((fieldName) => (
                  <TableCell align="center" key={fieldName}><strong>{fieldName}</strong></TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataObject.data.map((dataItem, index) => (
              <TableRow  key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: getDefaultRowColor(index) }}>
                {fieldNames.map((fieldName) => (
                  <TableCell align="center" key={fieldName}>
                    {dataItem[fieldName]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}

export default DynamicTable