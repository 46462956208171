import IconController from '../../containers/iconController';
import { config } from '../../../config/Constants';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Axios from 'axios';
import "./widget.css";  

const Widget = ({ type }) => {
  const [subLabel, setSubLabel] = useState();
  const [widgetNumber, setWidgetNumber] = useState();
  const [widgetDetails, setWidgetDetails] = useState();
  const [RECORD, setRecordValue] = useState();
  const [RECORD2, setRecord2Value] = useState();

  const widgetStyle = {
    backgroundColor: (widgetDetails && widgetDetails.WIDGET_COLOR) || '#FFFFFF'
  };  

  useEffect(() => {
    //setWidgetNumber('Calculating. . . ')
    getWidgetDetails(type);
    getWidgetData(type);
  }, [type]);

  const getCompany = () => {
    return Cookies.get('cp-cn');
  }

  const getWidgetDetails = async(type) => {
    const response = await Axios.get(config.url.GET_WIDGET_DETAILS, {
      params: {
        widgetId: type.widgetId
      }
    });
    setWidgetDetails(response.data.widgetDetails[0])
  }

  const getWidgetData = async(type) => {
    const companyName = getCompany();
    const response = await Axios.get(config.url.GET_WIDGET_DATA, {
      params: {
        widgetId: type.widgetId,
        companyName: companyName,
        carrier: type.carrier,
      }
    })
    console.log(response)
    setSubLabel(response.data.widgetValue.sublabels);
    setWidgetNumber(response.data.widgetValue.values);
    setRecordValue(response.data.widgetValue.RECORD);
    setRecord2Value(response.data.widgetValue.RECORD2);
  }

  return (
    <div className='widget' style={widgetStyle}>
      <div className="widgetPageContent">
        <div className="leftWidget">
          {widgetDetails && (
            <div className="widgetIcon">
              <IconController iconType={widgetDetails.WIDGET_ICON} />
            </div>
          )}
        </div>
        <div className="rightWidget">
          <div className="widgetTop">
            {widgetDetails && (
              <div className="widgetTitle">
                {widgetDetails.WIDGET_NAME}
              </div>
            )}
          </div>
          <div className="widgetMid">
            <div className="additionalData">
                {subLabel && subLabel.length > 0 ? (
                  <div className="widgetContainer2">
                    {subLabel.map((label, index)=>(
                      <div className="tessssssst" key={label.id}>
                        <div className="widgetSubheader">
                          {label.SUBHEADER}
                        </div>
                        {index !== subLabel.length - 1 && (
                            <div className="columnDivider"></div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div></div>
                )}
            </div>
            <div className="widgetValues">
              {widgetNumber && widgetNumber.length > 0 ? (
                <div className="widgetContainer2">
                  {Object.entries(widgetNumber[0]).map(([key, value], index)=> (
                    <div className="tessssssst" key={key}>
                      <div className="widgetSubheader">
                        {value}
                      </div>
                      {index !== Object.entries(widgetNumber[0]).length - 1 && (
                            <div className="columnDivider"></div>
                        )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="widgetSingleValue">
                  {RECORD && (
                    <div className="recordDisplay">
                      {RECORD}
                    </div>
                  )}
                  <div className="widgetRecord2">
                    {RECORD2 && (
                      <div className="record2Value">
                        {RECORD2}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="widgetBottom">
      </div>
    </div>
  );
}

export default Widget;