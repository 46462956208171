import CustomizedTitle from '../../../components/customizedTitle/CustomizedTitle';
import ToggleButton from '../../../components/common/ToggleButton';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBar from '../../../components/common/SearchBar';
import DataGrid from '../../../components/tables/DataGridAG';
import Table from '../../../components/tables/dynamicTable';
import Sidebar from '../../../components/sidebar/Sidebar';
import NavBar from '../../../components/navbar/NavBar';
import { config } from '../../../config/Constants';
import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Cookies from 'js-cookie';
import './formTracker.css';
import Axios from 'axios';

const FormTracker = () => {
  const [rows, setRows] = useState([]);
  const [toggle, setToggle] = useState('general'); //set to general so the default setting will always query to the general info in the toggle when a row is clicked
  const [searchValue, setSearchValue] = useState(null);
  const [tableSelect, setTableSelect] = useState('0'); //this is set to '0' so query would be id = '0', this way the query wont break and crash the backend
  const [toggleData, setToggleData] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [titleName, setTitleName] = useState(null);

  const generalDetails = { data: toggleData }
  const requestDetails = { data: toggleData }
  const originDetails = { data: toggleData }
  const destinationDetails = { data: toggleData }
  const pickupDetails = { data: toggleData }
  const deliveryDetails = { data: toggleData }
  const shipmentDetails = { data: toggleData }

  const searchConfig = { size: 'normal', keyPress: true}
  const dataObject = { data: rows, rowSelection: 'single', }
  const titleObject = {title: titleName}

  //this is set to refresh the table view to the all time record when the search bar is empty
  const inputValue = (value) => {
    if(value.length != 0) {
      setSearchValue(value);
    } 
    else {
      setIsLoading(true);
      getAllFormRecord();
      setIsLoading(false); 
      setSearchValue(null);
    }
  } 

  //this toggle object lists out the names for both the toggle objects and queries it is executing. backend has an if statement, selected query executes by providing the type.
  const toggleObject = {
    toggleButtons: ['general','requesting company', 'origin', 'destination', 'pickup', 'delivery', 'shipment' ],
    rows: rows,
    tableSelection: tableSelect,
  }


  const getAllFormRecord = async() =>  {
    const response = await Axios.get(config.url.GET_REPORT, {
      params: {
        reportName: 'formrecord',
        type: 'allTime',
        user: Cookies.get('cp-un'),
        companyName: Cookies.get('cp-cn')
      }
    });
    displayData(response.data);
  }

  useEffect(() => {
    getAllFormRecord(); 
    const title = 'Form Tracker';
    setTitleName(title)}, []);
  
  //when a new row is selected in the table, the toggle view is default to show the general data (form id, status, submitted by, submitted date)
  useEffect(() => {
    setIsLoading(true);
    async function showData() {
      const data = await getTableToggle('general');
      setToggleData(data);}
    showData();
    setIsLoading(false);
  }, [tableSelect]);

  const onSearch = (value) => {
    setSearchValue(value);
    handleSubmit();
  }

  const tableSelection = async (value) => {
    setTableSelect(value);
  }

  //warning and refuse submission if search bar is empty
  const handleSubmit = async() => {
    if (searchValue == null) {
      window.alert('No Form ID Submitted to the Search Bar.');
    }
    else {
      setIsLoading(true);
      const singleFormDataObject = await getSingleFormData();
      await displayData(singleFormDataObject);
      setToggleData({});
      setToggle('general');
      setIsLoading(false);
      setTableSelect('0');
    }
  }

  //in order to avod complicated logic to clear the search bar input, a refresh is implemented.
  const handleClear = async() => {
    window.location.reload();
  }

  const getSingleFormData = async() => {
    const response = await Axios.get(config.url.GET_REPORT, {
      params: {
        reportName: 'formrecord',
        type: 'single',
        formID: searchValue,
        user: Cookies.get('cp-un'),
        companyName: Cookies.get('cp-cn')
      }
    });
    return response.data;
  }

  const displayData = async(DataObject) => {
    if (DataObject.report && DataObject.report.length !== 0) {
      setRows(DataObject.report);
    } else {
      setRows([]);
    }
  }

  const toggleValue = async(value) => {
    if (value != null) {
      setToggle(value);
      const data = await getTableToggle(value);
      setToggleData(data);
    }
    else {
      const data = await getTableToggle(toggle);
      setToggleData(data);
    }
  }

  const getTableToggle = async(toggle) => {
    const response = await Axios.get(config.url.GET_REPORT, {
      params: {
        reportName: 'formrecord',
        type: toggle,
        user: Cookies.get('cp-un'),
        formID: tableSelect[0]["Form ID"],
      }
    });
    return response.data.report;
  }

  return (
    <div className="LandingPage">
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="formTrackerPageContent">
          <div className="formTrackerPageContentTop">
            <CustomizedTitle titleObject={titleObject}/>
          </div>
          <div className="formTrackerPageContentMid">
            <div className="formTrackerSearching">
              <div className="formTrackerSearch">
                <SearchBar inputValue={inputValue} onSearch={onSearch} config={searchConfig}/>
              </div>
              <div className="formTrackerSubmitSearch">
                <Button onClick={handleSubmit} variant='contained' disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
                <Button onClick={handleClear} variant='contained' disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : 'Clear'}
                </Button>
              </div>
            </div>
            <div className="formTrackerRow2">
              <div className="formTrackerShipmentBoard">
                  <DataGrid dataObject={dataObject} tableSelection={tableSelection}/>
              </div>
            </div>
          </div>
          {rows.some(row => Object.keys(row).length > 0) ? (
            <div className="formTrackerPageContentBottom">
              <div className="formTrackerToggle">
                <ToggleButton 
                  onClick={getTableToggle}
                  toggleValue={toggleValue}
                  toggleObject={toggleObject} />
              </div>
              <div className="formTrackerDetailTables">
                {toggle == 'general' ? (
                  <div className="formTrackerGeneralDetails">
                    <Table dataObject={generalDetails}/>
                  </div>
                  ) : toggle == 'requesting company' ? (
                  <div className="formTrackerRequestDetails">
                    <Table dataObject={requestDetails}/>
                  </div>
                  ) : toggle == 'origin' ? (
                  <div className="formTrackerOriginDetails">
                    <Table dataObject={originDetails}/>
                  </div>
                ) : toggle == 'destination' ? (
                  <div className="formTrackerDestinationDetails">
                    <Table dataObject={destinationDetails}/>
                  </div>
                ) : toggle == 'pickup' ? (
                  <div className="formTrackerPickupDetails">
                    <Table dataObject={pickupDetails}/>
                  </div>
                ) : toggle == 'delivery' ? (
                  <div className="formTrackerDeliveryDetails">
                    <Table dataObject={deliveryDetails}/>
                  </div>
                ) : toggle == 'shipment' ? (
                  <div className="formTrackerShipmentDetails">
                    <Table dataObject={shipmentDetails}/>
                  </div>
                ) : (<div> </div>)}
              </div>
            </div>
          ) : (<div> </div>)}
        </div>
      </div>
    </div>
  )
}

export default FormTracker