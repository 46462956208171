import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from './slices/sidebarSlice'; 
import chartFilterReducer from './slices/chartfilterSlice';
import summaryDataReducer from './slices/summarydataSlice';
import reportFilterReducer from  './slices/reportfilterSlice';

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    chartfilter: chartFilterReducer,
    summaryData: summaryDataReducer,
    report: reportFilterReducer,
  },
});

export default store;