import React, { useEffect, useState, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { sizeWidth } from '@mui/system';

const DataGridAG = ({dataObject, tableSelection}) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const gridApiRef = useRef(null); 

    useEffect(() => {
        const dataColumns = getColumns()
        setRows(dataObject.data);
        setColumns(dataColumns);
    }, [dataObject]);

    const getColumns = () => {
        if(dataObject && dataObject.data && dataObject.data.length > 0) {
            const dataColumns = Object.keys(dataObject.data[0]).map((key) => ({field: key}));
            return dataColumns
        }
    };

    const handleSelection = () => {
        const selectedNodes = gridApiRef.current.getSelectedNodes();
        const selectedData = selectedNodes.map((node) => node.data);
        tableSelection(selectedData);
    }

    const defaultColDef = useMemo(() => {
      return {
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1
      };
    }, []);

  return (
  <div className="ag-theme-alpine" style={{ height: '100%', width: '100%'}}>
    <AgGridReact
        rowData={rows}
        columnDefs={columns}
        rowSelection={dataObject.rowSelection}
        onSelectionChanged={handleSelection}
        onGridReady={(params) => {gridApiRef.current = params.api;}}
        overlayNoRowsTemplate={rows.length === 0 ? 'No Rows Found' : null}
        defaultColDef={defaultColDef}
    />
  </div>
  )
}

export default DataGridAG