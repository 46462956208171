import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { getReport } from '../../utils/api/reportData';

const useReportData = (reportName) => {
    const [reportData, setReportData] = useState(null);
  
    const fetchData = async () => {
      const data = await getReport(reportName, Cookies.get('cp-cn'));
      setReportData({ data: data, size: 'small' });
    }
  
    useEffect(() => {
      fetchData();
    }, []);
  
    return reportData;
  }
  
  export default useReportData;