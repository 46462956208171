import { useState } from 'react';

const useSelectedOptions = () => {
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelectValue = (option, value) => {
    setSelectedOptions(prevState => ({
      ...prevState, [option]: value
    }));
  };

  return [selectedOptions, handleSelectValue];
};

export default useSelectedOptions;