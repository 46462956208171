import SelectBox from '../../SelectBox';
import DateField from '../../datetime/DateField';

export const ModeFilter = ({ handleSelectValue }) => (
    <SelectBox 
      selectValue={(value) => handleSelectValue('Mode', value)}
      selectObject={{ label: 'Mode', menuItems: ['Parcel', 'TL', 'LTL'] }} 
    />
  );
  
export const DateFilter = ({ label, handleSelectValue }) => (
  <DateField 
    setDate={(value) => handleSelectValue(label, value)}
    dateObject={{label}}
  />
);

export const CarrierFilter = ({ handleSelectValue }) => (
  <SelectBox 
    selectValue={(value) => handleSelectValue('Carrier', value)}
    selectObject={{ label: 'Carrier', menuItems: ['UPS', 'FedEx'] }} 
  />
);

export const AccountNumberFilter = ({ handleSelectValue, accountNumberItems }) => (
  <SelectBox 
    selectValue={(value) => handleSelectValue('Account-Number', value)}
    selectObject={{ label: 'Account-Number', menuItems: accountNumberItems }} 
  />
);

export const SelectFilter = ({ handleSelectValue, items }) => (
  <SelectBox 
    selectValue={(value) => handleSelectValue('Select Filter', value)}
    selectObject={{ label: 'Select Filter', menuItems: items }} 
  />
);