import { getSelectData } from '../../utils/api/data';
import { useEffect, useState } from 'react';

const useSelectData = ({ selectValues, tableName, whereField, whereValue }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getSelectData({
        selectValues,
        tableName,
        whereField,
        whereValue,
      });
      setData(response);
    };
    getData();
  }, [selectValues, tableName, whereField, whereValue]);

  return data;
};

export default useSelectData;
